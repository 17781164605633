// import { EMessage } from '@aciga/e-ui-next';

// eslint-disable-next-line import/no-mutable-exports
let projectName = '';

export const setProjectName = (pname: string = '') => {
  projectName = pname;
};

export { projectName };

export const AppEnv = {
  // 判断是否是生产环境
  get isProduction() {
    return process.env.VUE_APP_ENV === 'production';
  },
  // 判断是否是测试环境
  get isTest() {
    return process.env.VUE_APP_ENV === 'staging';
  },
  // 判断是否是开发环境
  get isDevelopment() {
    return process.env.VUE_APP_ENV === 'development';
  }
};
